import { showToastError } from "../utils/toast";

import { removeCommasFromNumber, addComasToNumber } from "../utils/number";
import { addEventDelegation } from "../utils/dom/event";
const handleTHousandWidgetInput = (event: Event) => {

  const inputElement = event.target as HTMLInputElement;


  const originalValue = removeCommasFromNumber(inputElement.value);
  let value = isNaN(originalValue) ? 0 : originalValue;
  const max = parseInt(inputElement.max);
  const min = parseInt(inputElement.min);

  if (max && value > max) {
    const errorMessage = `Value cannot exceed ${addComasToNumber(max)}`;
    showToastError(errorMessage);
    value = max;
  }
  if (min && value < min) {
    const errorMessage = `Value cannot be lower than ${addComasToNumber(
      min
    )}`;
    showToastError(errorMessage);
    value = min;
  }

  // Update the input field with the formatted value
  inputElement.value = addComasToNumber(value);
}
addEventDelegation(document, 'input', 'thousands-widget', handleTHousandWidgetInput);


