
/**
 * Removes commas from a given number string and returns the resulting integer.
 *
 * @param {string} stringNumber - The number string with commas to be processed.
 * @returns {number} The integer value obtained after removing commas from the input string.
 */
function removeCommasFromNumber(stringNumber: string): number {

    return stringNumber == '' ? 0 : parseInt(stringNumber.replace(/\D/g, ""), 10)
}

// Function to format a number with commas
function addComasToNumber(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export { removeCommasFromNumber, addComasToNumber }